import { CredentialsKeyEnum } from '../provider.enum';
const mailConfigBase = [
    {
        key: CredentialsKeyEnum.From,
        displayName: 'From email address',
        description: 'Use the same email address you used to authenticate your delivery provider',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.SenderName,
        displayName: 'Sender name',
        type: 'string',
        required: true,
    },
];
const smsConfigBase = [
    {
        key: CredentialsKeyEnum.From,
        displayName: 'From',
        type: 'string',
        required: true,
    },
];
const pushConfigBase = [];
export const mailJsConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'Secret key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const mailgunConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.BaseUrl,
        displayName: 'Base URL',
        type: 'string',
        required: false,
    },
    {
        key: CredentialsKeyEnum.User,
        displayName: 'User name',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Domain,
        displayName: 'Domain',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const mailjetConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'API Secret',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const nexmoConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'API secret',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
export const mandrillConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const nodemailerConfig = [
    {
        key: CredentialsKeyEnum.User,
        displayName: 'User',
        type: 'string',
        required: false,
    },
    {
        key: CredentialsKeyEnum.Password,
        displayName: 'Password',
        type: 'string',
        required: false,
    },
    {
        key: CredentialsKeyEnum.Host,
        displayName: 'Host',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Port,
        displayName: 'Port',
        type: 'number',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Secure,
        displayName: 'Secure',
        type: 'boolean',
        required: false,
    },
    {
        key: CredentialsKeyEnum.RequireTls,
        displayName: 'Require TLS',
        type: 'switch',
        required: false,
    },
    {
        key: CredentialsKeyEnum.IgnoreTls,
        displayName: 'Ignore TLS',
        type: 'switch',
        required: false,
    },
    {
        key: CredentialsKeyEnum.TlsOptions,
        displayName: 'TLS options',
        type: 'object',
        required: false,
    },
    {
        key: CredentialsKeyEnum.Domain,
        displayName: 'DKIM: Domain name',
        type: 'string',
        required: false,
    },
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'DKIM: Private key',
        type: 'string',
        required: false,
    },
    {
        key: CredentialsKeyEnum.AccountSid,
        displayName: 'DKIM: Key selector',
        type: 'string',
        required: false,
    },
    ...mailConfigBase,
];
export const postmarkConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const sendgridConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.IpPoolName,
        displayName: 'IP Pool Name',
        type: 'string',
        required: false,
    },
    ...mailConfigBase,
];
export const resendConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const plunkConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const sparkpostConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Region,
        displayName: 'EU',
        description: 'Use `eu` if your account is registered to SparkPost EU',
        type: 'boolean',
        required: false,
    },
    ...mailConfigBase,
];
export const netCoreConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const sendinblueConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const sesConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'Access key ID',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'Secret access key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Region,
        displayName: 'Region',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const mailerSendConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const plivoConfig = [
    {
        key: CredentialsKeyEnum.AccountSid,
        displayName: 'Account SID',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Token,
        displayName: 'Auth token',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
export const sms77Config = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
export const termiiConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
export const burstSmsConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'API Secret',
        type: 'string',
        required: true,
    },
];
export const clickatellConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
];
export const snsConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'Access key ID',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'Secret access key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Region,
        displayName: 'AWS region',
        type: 'string',
        required: true,
    },
];
export const telnyxConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.MessageProfileId,
        displayName: 'Message profile ID',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
export const twilioConfig = [
    {
        key: CredentialsKeyEnum.AccountSid,
        displayName: 'Account SID',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Token,
        displayName: 'Auth token',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
export const slackConfig = [
    {
        key: CredentialsKeyEnum.ApplicationId,
        displayName: 'Application Id',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.ClientId,
        displayName: 'Client ID',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'Client Secret',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.RedirectUrl,
        displayName: 'Redirect URL',
        description: 'Redirect after Slack OAuth flow finished (default behaviour will close the tab)',
        type: 'string',
        required: false,
    },
    {
        key: CredentialsKeyEnum.Hmac,
        displayName: 'HMAC',
        type: 'switch',
        required: false,
    },
];
export const fcmConfig = [
    {
        key: CredentialsKeyEnum.ServiceAccount,
        displayName: 'Service Account (entire JSON file)',
        type: 'text',
        required: true,
    },
    ...pushConfigBase,
];
export const expoConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'Access Token',
        type: 'text',
        required: true,
    },
    ...pushConfigBase,
];
export const pushWebhookConfig = [
    {
        key: CredentialsKeyEnum.WebhookUrl,
        displayName: 'Webhook URL',
        type: 'string',
        description: 'the webhook URL to call to trigger push notifications',
        required: true,
    },
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'Secret Hmac Key',
        type: 'string',
        description: 'the secret used to sign webhooks calls',
        required: true,
    },
    ...pushConfigBase,
];
export const oneSignalConfig = [
    {
        key: CredentialsKeyEnum.ApplicationId,
        displayName: 'Application ID',
        type: 'text',
        required: true,
    },
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'text',
        required: true,
    },
    ...pushConfigBase,
];
export const apnsConfig = [
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'Private Key',
        type: 'text',
        required: true,
    },
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'Key ID',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.ProjectName,
        displayName: 'Team ID',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.ApplicationId,
        displayName: 'Bundle ID',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Secure,
        displayName: 'Production',
        type: 'switch',
        required: true,
    },
    ...pushConfigBase,
];
export const yiliWecomConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'text',
        required: true,
    },
    ...pushConfigBase,
];
export const gupshupConfig = [
    {
        key: CredentialsKeyEnum.User,
        displayName: 'User id',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Password,
        displayName: 'Password',
        type: 'string',
        required: true,
    },
];
export const firetextConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
export const outlook365Config = [
    {
        key: CredentialsKeyEnum.Password,
        displayName: 'Password',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const infobipSMSConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.BaseUrl,
        displayName: 'Base URL',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
export const infobipEmailConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.BaseUrl,
        displayName: 'Base URL',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
export const fortySixElksConfig = [
    {
        key: CredentialsKeyEnum.User,
        displayName: 'Username',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Password,
        displayName: 'Password',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
export const kannelConfig = [
    {
        key: CredentialsKeyEnum.Host,
        displayName: 'Host',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Port,
        displayName: 'Port',
        type: 'number',
        required: true,
    },
    {
        key: CredentialsKeyEnum.User,
        displayName: 'Username',
        type: 'string',
        required: false,
    },
    {
        key: CredentialsKeyEnum.Password,
        displayName: 'Password',
        type: 'string',
        required: false,
    },
    ...smsConfigBase,
];
export const maqsamConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'Access Key ID',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'Access Secret',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
export const smsCentralConfig = [
    {
        key: CredentialsKeyEnum.User,
        displayName: 'Username',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Password,
        displayName: 'Password',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.BaseUrl,
        displayName: 'Base URL',
        type: 'string',
        required: false,
    },
    ...smsConfigBase,
];
export const emailWebhookConfig = [
    {
        key: CredentialsKeyEnum.WebhookUrl,
        displayName: 'Webhook URL',
        type: 'string',
        description: 'the webhook URL to call instead of sending the email',
        required: true,
    },
    {
        key: CredentialsKeyEnum.SecretKey,
        displayName: 'Secret Hmac Key',
        type: 'string',
        description: 'the secret used to sign webhooks calls',
        required: true,
    },
    ...mailConfigBase,
];
export const convectAiProviderConfig = [
    {
        key: CredentialsKeyEnum.User,
        displayName: 'Email Auth Username',
        type: 'text',
        required: true,
    },
    {
        key: CredentialsKeyEnum.Password,
        displayName: 'Email Auth Password',
        type: 'string',
        required: true,
    },
];
export const africasTalkingConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: CredentialsKeyEnum.User,
        displayName: 'Username',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
export const novuInAppConfig = [
    {
        key: CredentialsKeyEnum.Hmac,
        displayName: 'Security HMAC encryption',
        type: 'switch',
        required: false,
        tooltip: {
            text: 'When active it verifies if a request is performed by a specific user',
            when: false,
        },
    },
];
export const sendchampConfig = [
    {
        key: CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
